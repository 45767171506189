import { useMessage } from "@messageformat/react";
import { Lang } from "./types";

export function useLang() {
  const lang = useMessage("lang", {});
  return lang as Lang;
}

export function prodEnv() {
  return (
    process.env.GATSBY_ACTIVE_ENV === undefined &&
    process.env.NODE_ENV === "production"
  );
}

export function devOrEmulatorEnv() {
  return !prodEnv();
}
