import posthog, { CaptureResult } from "posthog-js";
import { devOrEmulatorEnv } from "./utils";

function devLogEvent(event: CaptureResult | null) {
  if (devOrEmulatorEnv()) {
    console.log(`PostHog dev event:`, event);
    return null; // Event == null means don't send the event to PostHog
  }
  return event;
}

const posthogEnabled = true;
const posthogApiKey = process.env.GATSBY_POSTHOG_KEY || "";
const posthogHost = process.env.GATSBY_POSTHOG_HOST;

class PostHogAnalytics {
  private static instance: PostHogAnalytics;
  private initialized: boolean = false;

  private constructor() {
    if (typeof window !== "undefined" && posthogEnabled) {
      posthog.init(posthogApiKey, {
        api_host: posthogHost,
        persistence: "memory",
        disable_persistence: true,
        capture_pageview: false, // We'll handle this manually
        before_send: [devLogEvent],
      });
      this.initialized = true;
    }
  }

  public static getInstance(): PostHogAnalytics {
    if (!PostHogAnalytics.instance) {
      PostHogAnalytics.instance = new PostHogAnalytics();
    }
    return PostHogAnalytics.instance;
  }

  public pageView(url: string, properties?: Record<string, any>): void {
    if (!this.initialized) return;

    posthog.capture("$pageview", {
      url,
      ...properties,
    });
  }

  public track(eventName: string, properties?: Record<string, any>): void {
    if (!this.initialized) return;

    posthog.capture(eventName, properties);
  }

  public identify(distinctId: string, properties?: Record<string, any>): void {
    if (!this.initialized) return;

    posthog.identify(distinctId, properties);
  }

  public reset(): void {
    if (!this.initialized) return;

    posthog.reset();
  }
}

export const posthogAnalytics = PostHogAnalytics.getInstance();
